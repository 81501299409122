import { useReducer, useState } from "react"
import isEqual from 'react-fast-compare'

export const reducerDeepCompare = (state, action) => {
  const stateChanged = !isEqual(state, action)
  //console.log('effect stateChanged', state, action, stateChanged);
  return stateChanged
    ? action
    : state
}

const useStateDeepCompare = (defaultState) => {
  //console.log('1. useStateDeepCompare effect', defaultState);
  // FIXME: This assigns all state tot the same var
  // That causes an infinte loop
  const [state, _setState] = useState(defaultState)

  const setStateCompare = (newState) => {
    const stateChanged = !isEqual(state, newState)

    if (stateChanged) {
      console.log('2. useStateDeepCompare effect changed', state, newState, stateChanged);
      _setState(newState)
    }

  }

  return [state, setStateCompare]

}

export default useStateDeepCompare